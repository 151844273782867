import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAnvnApeidDUqKGqQI0BrL0FQtdvTF3LOM",
    authDomain: "carwash-68c7f.firebaseapp.com",
    projectId: "carwash-68c7f",
    storageBucket: "carwash-68c7f.appspot.com",
    messagingSenderId: "286197624029",
    appId: "1:286197624029:web:1e3e90108141a320ef3f34"
};

const app = initializeApp(firebaseConfig);


const firestore = initializeFirestore(app, {
    experimentalForceLongPolling: true
})
export default firestore;