import React from "react";
import FormComponent from "../components/FormComponent/FormComponent";
import backgroundImage from "../utils/images/homepagecar.png";
import logo from "../utils/images/Myrtle beach.png";
import offer from "../utils/images/offer.png";

function Home() {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="bg-gray-900 min-h-screen px-4 md:px-40 relative">
            {/* Main Content */}
            <div className="flex justify-center bg-gray-900 z-1">
                <img src={logo} className="w-36 md:w-48 h-auto -mt-6" alt="Logo" />
            </div>
            <main
                className="bg-black w-full flex flex-col md:flex-row justify-center items-center p-6 md:p-10 z-10 rounded-lg relative"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                {/* Offer image */}


                {isMobile ? (
                    <img src={offer} className="w-60  h-auto" alt="Offer" />
                ) : (
                    <img src={offer} className="w-60  h-auto absolute top-0 left-0 mt-4 ml-4 md:mt-0  " alt="Offer" />
                )}


                <div className="w-full md:w-3/4 flex flex-col md:mt-60 md:ml-8">
                    <div className="font-barlow-condensed-bold text-white font-bold text-4xl md:text-6xl mb-4">
                        ENTER YOUR INFORMATION
                    </div>
                    <div className="font-barlow-condensed-bold font-bold text-custom-blue text-4xl md:text-6xl">
                        TO GET 40% DISCOUNT ON YOUR FIRST PURCHASE!
                    </div>
                </div>
                <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <FormComponent />
                </div>
            </main>

            {/* Box with three cards */}
            <div className="flex flex-col rounded-lg overflow-hidden -mt-4">
                <div className="flex flex-col shadow-lg">
                    <div className="text-white bg-custom-blue pb-8 pt-10 px-6 md:px-12">
                        <h1 className="text-2xl md:text-3xl font-semibold">
                            Our Services
                        </h1>
                        <p className="text-sm md:text-base">See service details below</p>
                    </div>
                    <div className="flex flex-col md:flex-row p-4 md:justify-center bg-white">
                        <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                            <div className="bg-gradient-to-br from-gray-100 to-gray-400 border-2 border-gray-400 shadow-lg h-48 mb-4 justify-center items-center rounded-md flex flex-col">
                                <p className="font-barlow-condensed-bold text-white font-bold text-4xl md:text-5xl">
                                    STANDARD
                                </p>
                            </div>
                            <div className="mx-4">
                                <p className="text-xl md:text-2xl font-semibold mb-1">
                                    Standard Service
                                </p>
                                <div className="w-auto h-0.5 bg-custom-blue mb-4 mr-48" />
                                <ol>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Exterior Hand Wash</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Deep Wheel Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Vacuum Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Glass Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Interior Detail Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Door jam Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Trunk Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Pre-wash</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Interior Steam Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Iron Spray and Clay Bar</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Spray Wax</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Seat and Upholstery Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                </ol>
                                <div className="flex justify-between">
                                    <p className="text-gray-900 text-lg md:text-base font-bold ">Price</p>
                                    <p className="text-blue-600 text-lg md:text-base font-bold">$99.99</p>
                                </div>
                            </div>
                        </div>
                        {isMobile && <hr className="w-full border-gray-300 my-4 md:hidden" />}
                        <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                            <div className="bg-gradient-to-br from-yellow-300 to-yellow-600 border-2 border-gray-400 shadow-lg h-48 mb-4 justify-center items-center rounded-md flex flex-col">
                                <p className="font-barlow-condensed-bold text-white font-bold text-4xl md:text-5xl">
                                    GOLD
                                </p>
                            </div>
                            <div className="mx-4">
                                <p className="text-xl md:text-2xl font-semibold mb-1">
                                    Gold Service
                                </p>
                                <div className="w-auto h-0.5 bg-custom-blue mb-4 mr-60" />
                                <ol>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Exterior Hand Wash</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Deep Wheel Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Vacuum Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Glass Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4"> Interior Detail Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Door jam Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Trunk Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Pre-wash</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Interior Steam Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Iron Spray and Clay Bar</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Spray Wax</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Seat and Upholstery Cleaning</p>
                                        <div className="w-6 h-6 bg-gray-400 rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                            </svg>
                                        </div>
                                    </li>
                                </ol>
                                <div className="flex justify-between">
                                    <p className="text-gray-900 text-lg md:text-base font-bold ">Price</p>
                                    <p className="text-blue-600 text-lg md:text-base font-bold">$199.99</p>
                                </div>
                            </div>
                        </div>
                        {isMobile && <hr className="w-full border-gray-300 my-4 md:hidden" />}
                        <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                            <div className="bg-gradient-to-br from-blue-800 to-gray-800 border-2 border-gray-400 shadow-lg h-48 mb-4 justify-center items-center rounded-md flex flex-col">
                                <p className="font-barlow-condensed-bold text-white font-bold text-4xl md:text-5xl">
                                    PREMIUM
                                </p>
                            </div>
                            <div className="mx-4">
                                <p className="text-xl md:text-2xl font-semibold mb-1">
                                    Premium Service
                                </p>
                                <div className="w-auto h-0.5 bg-custom-blue mb-4 mr-48" />
                                <ol>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Exterior Hand Wash</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Deep Wheel Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Vacuum Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Glass Cleaning<span className="font-bold">+ Coating</span></p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4"><span className="font-bold">Full</span>  Interior Detail Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Door jam Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Trunk Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Pre-wash</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Interior Steam Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Iron Spray and Clay Bar</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Spray Wax</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                    <li className="flex flex-wrap justify-between">
                                        <p className="text-gray-700 md:text-md mb-4">Seat and Upholstery Cleaning</p>
                                        <div className="w-6 h-6 bg-custom-blue rounded-full flex items-center justify-center">
                                            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.707-10.707a1 1 0 00-1.414-1.414L9 10.586 6.707 8.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </li>
                                </ol>
                                <div className="flex justify-between">
                                    <p className="text-gray-900 text-lg md:text-base font-bold ">Price</p>
                                    <p className="text-blue-600 text-lg md:text-base font-bold">$299.99</p>
                                </div>
                            </div>
                        </div>
                        {isMobile && <hr className="w-full border-gray-300 my-4 md:hidden" />}
                    </div>

                </div>
            </div>

            {/* Footer */}
            <footer className="bg-gray-900 text-white py-4">
                <div className="container mx-auto">
                    <p className="text-center">&copy; 2024 Myrtle Beach Auto Care</p>
                    <p className="text-center">&copy; Powered by <a href="https://www.linkedin.com/in/mehmetdaskaya/" target="_blank">Daskaya</a></p>
                </div>
            </footer>
        </div>
    );
}

export default Home;
