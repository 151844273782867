import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import firestore from '../../utils/endpoints/firebase';

const FormComponent = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState("");
    const isMobile = window.innerWidth <= 768;

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await addDoc(collection(firestore, 'users'), {
                name: name,
                email: email,
                phone: phone
            });
            setSubmissionStatus('success');
            console.log('Data added to Firestore');
            setName('');
            setEmail('');
            setPhone('');
        } catch (error) {
            console.error('Error adding document: ', error);
            setSubmissionStatus('error');
        }
        setLoading(false);
    };

    const renderForm = () => {
        return (
            <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg p-6 md:p-8">
                <h2 className="text-xl md:text-2xl font-bold mb-6">Sign Up for Offers</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-custom-blue shadow-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-700 font-semibold mb-2">Phone:</label>
                        <input
                            type="tel"
                            id="phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-custom-blue shadow-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-custom-blue shadow-lg"
                        />
                    </div>

                    <button type="submit" className="bg-custom-blue w-full text-white py-2 px-4 rounded-lg hover:bg-blue-800 shadow-lg transition duration-300">Submit and get your offer</button>

                </form>
                <div className='mt-4'>
                    <p className='text-xs text-center'>
                        By providing your number and email address you consent to and are opting-in to receive text messaging and emails from Myrtle Beach Auto Care in connection with your request. By Clicking "Submit and get your offer" You are Agreeing to Our Terms & Conditions & Privacy Policy.
                    </p>
                    {isMobile ? (<div className='flex flex-row  justify-center mt-4 '>
                        <div className='text-center' >
                            <a href='#' className='hover:text-custom-blue mx-2 text-sm'>Terms & Conditions</a>
                        </div>
                        <>|</>
                        <div className='text-center'>
                            <a href='#' className='hover:text-custom-blue mx-2 text-sm'>Privacy Policy</a>
                        </div>
                    </div>) : (<div className='flex flex-row justify-center mt-2 '>
                        <div className='text-center' >
                            <a href='#' className='hover:text-custom-blue mx-6 md:text-sm'>Terms & Conditions</a>
                        </div>
                        <>|</>
                        <div className='text-center'>
                            <a href='#' className='hover:text-custom-blue mx-6 md:text-sm'>Privacy Policy</a>
                        </div>
                    </div>)}

                </div>
            </div>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div className="max-w-md mx-auto bg-green-50 rounded-lg overflow-hidden shadow-lg p-6 md:p-8 border border-green-400">
                <p className="text-green-700 font-semibold text-lg">
                    Submission Successful
                </p>
                <p className="text-gray-800 mt-2">
                    Thank you for submitting your information. We will contact you within 24 hours.
                </p>
            </div>
        );
    };

    const renderErrorMessage = () => {
        return (
            <div className="max-w-md mx-auto bg-red-50 rounded-lg overflow-hidden shadow-lg p-6 md:p-8 border border-red-400">
                <p className="text-red-700 font-semibold text-lg">
                    Submission Error
                </p>
                <p className="text-gray-800 mt-2">
                    There was an error processing your request. Please refresh the page and try again later.
                </p>
            </div>
        );
    };


    return (
        <>
            {loading && <div>Loading...</div>}
            {submissionStatus === 'success' ? renderSuccessMessage() : submissionStatus === 'error' ? renderErrorMessage() : renderForm()}
        </>
    );
};

export default FormComponent;
